import React from "react"
import styled from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"
import SEO from "~/components/seo"
import Header from "~/components/Header"
import Container from "~/components/Container"
import { Columns, Column, Intro } from "~/components/Layout"
import { PrimaryTitle, SecondaryTitle, Lead } from "~/components/Typography"
import ReactMarkdown from "react-markdown"
import pageData from "../../content/pages/donate.json"

export default () => {
  const {
    metaTitle,
    metaDescription,
    metaImage,
    title,
    lead,
    text,
    youtubeId,
    gofundmeUrl,
    secondaryHeading,
    secondaryText,
    table,
  } = pageData

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      <Header />
      <Wrapper>
        <Intro gradient="#e9dbd9" gradientFixed>
          <Container>
            <Title>{title}</Title>
            <Columns>
              <Column>
                <Lead>
                  <ReactMarkdown
                    children={lead}
                    disallowedElements={["p"]}
                    unwrapDisallowed
                  />
                </Lead>
                <Text>
                  <ReactMarkdown children={text} />
                </Text>
                <GfmEmbed>
                  <GfmEmbedInner>
                    <iframe src={gofundmeUrl} title="gofundme"></iframe>
                  </GfmEmbedInner>
                </GfmEmbed>
                <SecondaryTitle>{secondaryHeading}</SecondaryTitle>
                <Text>
                  <ReactMarkdown children={secondaryText} />
                </Text>
              </Column>
              <Column>
                <IntroVideoWrapper>
                  <IntroVideoInner>
                    <iframe
                      src={`https://www.youtube-nocookie.com/embed/${youtubeId}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="youtube"
                    ></iframe>
                  </IntroVideoInner>
                </IntroVideoWrapper>
              </Column>
            </Columns>

            <ExpensesTables dangerouslySetInnerHTML={{ __html: table }} />
          </Container>
        </Intro>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div``

const Title = styled(PrimaryTitle)`
  margin-bottom: 50px;
  background: -webkit-linear-gradient(#01947d, #395489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Text = styled.div`
  p {
    margin-bottom: 1em;

    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      transition: 0.15s;

      &:hover {
        color: #829794;
      }
    }
  }
`

const GfmEmbed = styled.div`
  margin-top: 52px;
  margin-bottom: 150px;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04), 0 6px 8px rgba(0, 0, 0, 0.04),
    0 8px 16px rgba(0, 0, 0, 0.04);
`

const GfmEmbedInner = styled.div`
  width: 100%;
  max-width: 345px;
  height: 208px;
  overflow: hidden;
  position: relative;
  border-radius: 3px;

  iframe {
    display: block;
    width: calc(100% + 18px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border: none;
  }

  ${media.desktop} {
    height: 178px;
  }
`

const IntroVideoWrapper = styled.div`
  position: relative;
  border: 7px solid #fff;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
`

const IntroVideoInner = styled.div`
  padding-top: 56.25%;

  iframe {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
`

const ExpensesTables = styled.div`
  margin-top: 80px;

  table {
    width: 100%;
    border: 3px solid;
    border-width: 3px 0;
    border-collapse: collapse;
    border-spacing: 0;
  }

  thead {
    th {
      border-bottom: 1px solid;
      text-align: left;
      font-weight: bold;
    }
  }

  tbody {
    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #333;
    }

    tr:last-child td {
      border-color: currentColor;
    }
  }

  tfoot {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  th,
  td {
    padding: 5px 20px;
    font-size: 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:nth-child(even) {
      text-align: right;
    }
  }

  th {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  td {
    &:nth-child(even) {
      font-family: monospace;
    }
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    table {
      margin-bottom: 40px;

      &:first-child {
        display: none;
      }
    }

    th,
    td {
      padding: 5px 0 !important;
    }
  }

  ${media.tablet} {
    table {
      &:not(:first-child) {
        display: none;
      }
    }
  }
`
